import React from "react"
import classNames from "classnames"
import * as styles from "./FocusTiles.module.scss"
import Icon from "components/Icon"
import Link from "components/Link"
import { useQuery } from "@tanstack/react-query"

interface Props {
  getCount: () => Promise<{ count: number }>
  href?: string
  onClick?: () => void
  selected?: boolean
  subtitle: string
  title: string
}

const FocusTile: React.FC<Props> = ({
  getCount,
  href,
  onClick,
  selected = false,
  subtitle,
  title,
}) => {
  const { isFetching, error, data } = useQuery({
    queryKey: ["focusTile", title.trim()],
    queryFn: getCount,
    refetchOnWindowFocus: false,
  })

  const Count: React.FC = () => {
    if (isFetching) {
      return (
        <Icon
          className={styles.spinner}
          size="2x"
          spin={true}
          type="spinner"
          ariaLabel="loading count"
        />
      )
    }
    if (error) {
      return null
    }
    return (
      <div className={styles.count} aria-label={`${title} count`}>
        {data?.count}
      </div>
    )
  }

  const Container: React.FC = ({ children }) => {
    if (href)
      return (
        <Link className={styles.container} href={href} onClick={onClick}>
          {children}
        </Link>
      )
    return (
      <button
        className={classNames(styles.container, selected && styles.selected)}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }

  return (
    <Container>
      <div className={styles.innerContainer}>
        <h2 className={styles.title}>{title}</h2>
        <Count />
      </div>
      <h3 className={styles.subtitle}>{subtitle}</h3>
    </Container>
  )
}

export default FocusTile
