// @ts-strict-ignore
import React from "react"
import { Formik } from "formik"
import QuestionTitle from "./QuestionTitle"
import { hasTruthyProperty } from "utilities/object"
import { SurveyQuestion, SurveyAnswerValue } from "sharedTypes"
import { Values } from "../sharedTypes"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

type ConfigType = {
  validate?(values: Values, question?: SurveyQuestion): object
  mapQuestionToValues?(
    question: SurveyQuestion
  ): {
    answerValue: SurveyAnswerValue
  }
  mapValuesToQuestion?(values: Values, question: SurveyQuestion): Values
  isButtonVisible?(params): boolean
  submitHelpText?: React.ReactNode
}

type ComponentProps = {
  question: SurveyQuestion
  onAnswer(answerType: string, answerValue: SurveyAnswerValue): Promise<void>
  showMostCommon: boolean
  setFieldSaving(boolean): void
  requiresConfirmation: boolean
  isIncomplete: boolean
}

const defaultConfig: ConfigType = {
  mapQuestionToValues: (question: SurveyQuestion) => ({
    answerValue: question.answerValue || "",
  }),
  mapValuesToQuestion: (values: Values) => values,
  validate: (values: Values) => {
    if (values.answerValue) {
      return {}
    }
    return { answerValue: "is required" }
  },
  isButtonVisible: ({ dirty, errors }) => dirty && !hasTruthyProperty(errors),
  submitHelpText: (
    <>
      Press <strong>Enter &crarr;</strong> or <strong>Tab &#8677;</strong>
    </>
  ),
}

const asQuestion = (Component: React.ReactType, config: ConfigType) =>
  React.forwardRef<HTMLInputElement, ComponentProps>((props, ref) => {
    const {
      question,
      onAnswer,
      showMostCommon,
      setFieldSaving,
      requiresConfirmation,
      isIncomplete,
    } = props
    const configuration = { ...defaultConfig, ...config }
    const {
      validate,
      mapQuestionToValues,
      mapValuesToQuestion,
      isButtonVisible,
      submitHelpText,
    } = configuration

    const questionId = `question-${question.questionId}`
    const isInitial = question.answerValue === null

    return (
      <Formik
        enableReinitialize
        initialValues={mapQuestionToValues(question)}
        validate={validate && ((values) => validate(values, question))}
        onSubmit={(values, { setSubmitting }) => {
          setFieldSaving && setFieldSaving(true)
          const { answerType, answerValue } = mapValuesToQuestion(
            values,
            question
          )
          onAnswer(answerType || "value", answerValue).then(() => {
            setSubmitting(false)
            setFieldSaving && setFieldSaving(false)
          })
        }}
      >
        {(formProps) => {
          const valid = Object.keys(formProps.errors).reduce(
            (currentlyValid, field) => {
              return currentlyValid && !formProps.errors[field]
            },
            true
          )

          return (
            <form onSubmit={formProps.handleSubmit}>
              <div className="form-group">
                <QuestionTitle
                  questionTitle={question.title}
                  questionText={question.text}
                  tooltips={question.tooltips}
                  questionId={questionId}
                />
                <Component
                  question={question}
                  inputId={questionId}
                  showMostCommon={showMostCommon}
                  requiresConfirmation={requiresConfirmation}
                  ref={ref}
                  {...formProps}
                />
                {isButtonVisible({ ...formProps, valid, isInitial }) && (
                  <>
                    <div className="d-flex align-items-center">
                      <button
                        type="submit"
                        className="btn btn-brand"
                        disabled={formProps.isSubmitting || !valid}
                      >
                        {formProps.isSubmitting ? "Saving" : "Save"}
                      </button>
                      <p className="canopy-mbe-0 canopy-mis-12x font-xs">
                        {submitHelpText}
                      </p>
                    </div>
                    {isIncomplete && (
                      <div className="canopy-mt-4x d-flex align-items-start">
                        <CanopyIcon
                          size="small"
                          name="circle-exclamation"
                          fill="canopyColorTextDanger"
                          className="col-auto"
                        />
                        <div className="col canopy-pl-2x color-danger canopy-typography-body-xsmall">
                          Saving is required
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </form>
          )
        }}
      </Formik>
    )
  })

export default asQuestion
