import { DocumentationRowProps } from "./documentation-row-props"
import { ChartNote, DocumentationRequirementSatisfyMethod } from "sharedTypes"
import Button from "components/form/Button"
import { Link } from "react-router-dom"
import { EventCategory, trackEvent } from "utilities/tracking"
import { chartNotePath } from "../../../../../routes"
import { slugify } from "utilities/string"
import { ToggleSatisfyMethod } from "./ToggleSatisfyMethod"
import React from "react"

const EVENT_PREFIX = "documentation-requirement-edit"

type EditChartNoteLinkProps = Pick<
  DocumentationRowProps,
  "bounceToSection" | "documentationRequirement" | "setShowIcd10Error"
> & {
  chartNote: ChartNote
}
const EditChartNoteLink = (props: EditChartNoteLinkProps) => {
  const { chartNote, bounceToSection, setShowIcd10Error } = props
  const { shouldBounce, triggerBounce } = bounceToSection || {}

  const handleClick = () => {
    setShowIcd10Error && setShowIcd10Error(true)
    triggerBounce()
  }

  return shouldBounce ? (
    <Button
      className="link canopy-mis-4x canopy-pie-4x font-subparagraph float-right"
      onClick={handleClick}
      data-test-id={`${chartNote.id}-edit-button`}
    >
      Edit
    </Button>
  ) : (
    <Link
      className="link canopy-mx-4x font-subparagraph float-right"
      onClick={() => {
        trackEvent(
          EventCategory.Activation,
          `${EVENT_PREFIX}-${props.documentationRequirement.satisfyStatus}`
        )
      }}
      to={chartNotePath(slugify(chartNote.name), chartNote.id)}
      data-test-id={`${chartNote.id}-edit-button`}
    >
      Edit
    </Link>
  )
}

type GenerateDetailsProps = Pick<
  DocumentationRowProps,
  | "chartNote"
  | "permissions"
  | "missingChartNotesPrerequisites"
  | "documentationRequirement"
  | "bounceToSection"
  | "setShowIcd10Error"
>

export const GenerateDetails = (props: GenerateDetailsProps) => {
  const {
    chartNote,
    documentationRequirement,
    permissions,
    missingChartNotesPrerequisites,
  } = props
  const chartNoteEditable =
    chartNote && permissions.updateChartNotes && !missingChartNotesPrerequisites

  const isDocumentParachuteGenerated =
    documentationRequirement.satisfyMethod ===
    DocumentationRequirementSatisfyMethod.Generate
  const showEditChartNoteLink =
    chartNoteEditable && isDocumentParachuteGenerated

  return (
    <div className="row">
      <div className={showEditChartNoteLink ? "col-9" : "col-12"}>
        {!documentationRequirement.chartNotesRequired && (
          <div className="color-dark-gray font-subparagraph">
            Parachute creates the script for you
          </div>
        )}
        <ToggleSatisfyMethod {...props} />
      </div>
      {showEditChartNoteLink && (
        <div className="col-3">
          <EditChartNoteLink {...props} chartNote={chartNote} />
        </div>
      )}
    </div>
  )
}
